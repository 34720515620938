import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"

const Blog = ({ data }) => {
  return (
    <Layout>
      <SEO title="Blog" />

      <Hero title="Welcome," subtitle="This is the Codestack blog section." />

      <div>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <div key={node.id}>
            <section className="section blog">
              <div className="columns">
                <div className="column is-8 is-offset-2">
                  <div className="content">
                    <div className="blog-item-header">
                      <Link to={node.fields.slug}>
                        <h1 className="title">{node.frontmatter.title}</h1>
                        <div className="subtitle is-size-7">
                          {node.frontmatter.date} - {node.frontmatter.author}
                        </div>
                      </Link>
                    </div>
                    <div className="blog-item-summary">
                      <p>
                        {node.frontmatter.summary}{" "}
                        <Link to={node.fields.slug}>Read</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="is-divider"></div>
          </div>
        ))}
      </div>
    </Layout>
  )
}
export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            summary
            author
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`

export default Blog
